<template>
  <star-rating
    :read-only="true"
    :star-size="size"
    :increment="0.5"
    :rating="count"
    :show-rating="false"
    :rounded-corners="true"
    active-color="#F9BB0D"
    inactive-color="#efefef"
    :border-width="2"
    border-color="#CCCCCC"
    data-toggle="tooltip"
    data-placement="left"
    :title="info"
    :class="cssClass"
  />
</template>
<script>
import StarRating from 'vue-star-rating'
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    info: {
      type: String
    },
    size: {
      type: Number,
      default: 20
    },
    cssClass: {
      type: String
    }
  },
  components: { StarRating }
}
</script>
